import propsToCss from '@styled-system/css'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import Box, { BoxStyleProps } from '../primitives/Box'
import CardFrame from '../primitives/CardFrame'

type Props = {
  title: ReactNode
  text: ReactNode
  icon?: string
  image?: IGatsbyImageData
  alt?: string
  isLarge?: boolean
  isFullHeight?: boolean
  href?: string
  target?: string
  imageAlign?: BoxStyleProps['justifyContent']
  clipImage?: boolean
}

const transition = 'all 0.15s ease-out 0s' as const

const Blurb = ({
  title,
  text,
  icon,
  image,
  alt,
  isLarge = false,
  isFullHeight = true,
  href,
  target,
  imageAlign = 'center',
  clipImage = false,
}: Props) => {
  const hasLink = href !== undefined
  const content = (
    <CardFrame
      display="flex"
      flexDirection="column"
      height={isFullHeight ? '100%' : undefined}
      className="card-frame"
    >
      <Box padding={32} paddingBottom={image ? 16 : 32}>
        {icon ? (
          <Box.img width={32} loading="lazy" src={icon} marginBottom={12} />
        ) : null}

        <Box.h5 font="h5" marginBottom={4}>
          {title}
          {hasLink && (
            // Adapted from src/components/system/Button.tsx
            <Box.span
              className="arrow"
              display="inline-block"
              left={0}
              position="relative"
              // @ts-expect-error
              fontSize="inherit"
              paddingLeft={8}
              verticalAlign="middle"
              lineHeight="20px"
              transition={transition}
              css="margin-right: -6px;"
            >
              &rarr;
            </Box.span>
          )}
        </Box.h5>

        <Box
          font="small"
          color="black600"
          maxWidth={{ xs: 'full', md: isLarge ? 'largeBlurb' : 'blurb' }}
        >
          {text}
        </Box>
      </Box>
      {image ? (
        <Box
          width="100%"
          marginTop="auto"
          display="flex"
          justifyContent={imageAlign}
        >
          <GatsbyImage
            image={image}
            alt={alt || ''}
            imgStyle={
              clipImage ? { objectFit: 'cover', height: '100%' } : undefined
            }
            style={clipImage ? { height: image.height } : undefined}
          />
        </Box>
      ) : null}
    </CardFrame>
  )

  const linkStyle = propsToCss({
    transition,
    '&:hover .card-frame': {
      cursor: 'pointer',
      borderColor: '#D0D4DE',
      backgroundImage: 'linear-gradient(180deg, #EBECF1 0%, #E5E6ED 100%)',
    },
    '&:hover .arrow': {
      left: 2,
    },
  })

  return !hasLink ? (
    content
  ) : href.startsWith('http') ? (
    <Box.a css={linkStyle} href={href} target={target}>
      {content}
    </Box.a>
  ) : (
    <Box.Link css={linkStyle} to={href} target={target}>
      {content}
    </Box.Link>
  )
}

export default Blurb
