import React, { ReactNode } from 'react'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import { BlockPaddingProps } from './types'

type Props = BlockPaddingProps &
  (
    | {
        columns: [ReactNode, ReactNode, ReactNode]
        flex?: [number, number, number]
      }
    | {
        columns: [ReactNode, ReactNode]
        flex?: [number, number]
      }
  )

export const gridSpacing = { xs: 16, md: 24 } as const

const ColumnsBlock = ({ columns, paddingTop, paddingBottom, flex }: Props) => {
  return (
    <Block paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Row
        maxWidth="largeBlock"
        space={gridSpacing}
        justifyContent="center"
        css={{ margin: 'auto' }}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        {columns.map((column, index) => (
          <Box key={`columns-${index}`} flex={flex?.[index] ?? 1}>
            {column}
          </Box>
        ))}
      </Row>
    </Block>
  )
}

export default ColumnsBlock
